import React from 'react'

function Footer() {
    return (
        <div><footer className="text-center py-4 text-white">
            <p>&copy; Created By Shiwang Pandey |  2020 All rights reserved.</p>
        </footer></div>
    )
}

export default Footer